<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info">
      <template v-slot:filter>
        <filter-container>
          <template v-slot:content>
            <product-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler" />
          </template>
        </filter-container>
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.brand.name`]="{ item }">
            <div class="camelcase">
              {{ item.brand.name }}
            </div>
          </template>
          <template v-slot:[`item.productTypeName`]="{ item }">
            <div class="camelcase">
              {{ item.productTypeName }}
            </div>
          </template>
          <template v-slot:[`item.productKit`]="{ item }">
            <v-icon v-if="item.productKit" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.categories`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip class="itens">{{ item.categories.length }}</v-chip>
            </div>
          </template>
          <template v-slot:[`item.skus`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip class="itens">{{ item.skus.length }}</v-chip>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="camelcase" :class="getColorStatus(item.status)">
              {{ ProdutoStatusOptions[item.status] }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.id)">mdi-magnify-plus-outline</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>

    <AlertModal :showModal="showModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">
            Todas as informações deste tipo de produto serão perdidas com esta ação
          </div>
          <div>Deseja mesmo excluir o tipo de produto?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR EXCLUSÃO</v-btn>
        <v-btn color="primary" @click="remove()">SIM, QUERO EXCLUIR</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import AlertModal from '@/components/modal/AlertModal';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import FilterContainer from '@/components/filter-layout/FilterContainer';
import { ProdutoStatusOptions } from '@/static-data/ProdutoStatusOptions';
import notification from '@/service/notification';
import { routerPush } from '@/service/kplaceRouter';
import { deleteSellerProduct, getListProduct } from '@/service/product/product-service';
import ProductFormFilter from './filter/ProductFormFilter';

export default {
  components: {
    ListLayout,
    AlertModal,
    ProductFormFilter,
    FilterContainer,
  },
  mixins: [dataCrudMixin],
  computed: {
    readOnly() {
      return true;
    },
  },
  data() {
    return {
      ProdutoStatusOptions,
      title: 'Produtos',
      loading: false,
      formDataFilter: {},
      paginationOptions: {},
      search: '',
      productClickedItem: {},
      showModal: false,
      info:
        'Cadastre, edite e acompanhe os produtos que serão disponibilizados em nossas plataformas de venda.',
      headers: [
        { text: 'Código', value: 'code', sortable: true },
        { text: 'Nome', value: 'name', sortable: true },
        { text: 'Marca', value: 'brand.name' },
        { text: 'Categoria', value: 'categories' },
        { text: 'Tipo de produto', align: 'start', value: 'productTypeName', sortable: true },
        { text: 'Kit', value: 'productKit' },
        { text: 'SKUs', align: 'start', value: 'skus' },
        { text: 'Status', align: 'start', value: 'status', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    async init() {
      this.getProductListHandler(this.formDataFilter);
    },
    getColorStatus(status) {
       switch (ProdutoStatusOptions[status]) {
        case ProdutoStatusOptions.draft:
          return 'color--orange-0';
        case ProdutoStatusOptions.approved:
          return 'color--blue-0';
        case ProdutoStatusOptions.rejected:
          return 'color--red-0';
        case ProdutoStatusOptions.published:
          return 'color--green-0';
        case ProdutoStatusOptions.inactive:
        return 'color--red-0';
        case ProdutoStatusOptions.banned:
        return 'color--red-0';
        default:
          return '';
      }
    },
    async getProductListHandler(formDataFilter) {
      try {
        this.loading = true;
        this.orderInit();
        const requestReponse = await getListProduct(formDataFilter, this.paginationOptions);
        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.content = requestReponse.data.data;
          this.totalElements = requestReponse.data.totalElements;
        } else {
          notification(`${requestReponse.response.data.message}`, 'error');
        }
      } catch (e) {
        console.error('Error on getProductListHandler', e);
        notification(`Erro ao realizar busca de tipos de produtos: ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getProductListHandler(this.formDataFilter);
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getProductListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
    openDetail(number) {
      routerPush(`/product/forms/edit/${number}`);
    },
    showAlertModal(item) {
      this.productClickedItem = item;
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    async remove() {
      try {
        const item = this.productClickedItem;
        const response = await deleteSellerProduct(item.id);
        if (response.status === 204) {
          notification('Produto removido com sucesso.', 'success');
          this.content = this.content.filter((e) => e.id !== item.id);
        } else {
          notification('Erro ao remover produto.', 'error');
        }
      } catch (error) {
        notification(`Erro ao remover o produto: ${error.response.data.message}`, 'error');
      }
      this.productClickedItem = {};
      this.showModal = false;
    },
    orderInit() {
      if (this.paginationOptions.sortBy.length === 0) {
      this.paginationOptions.sortBy.push('name');
      this.paginationOptions.sortDesc.push(false);
    }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}

.itens.theme--light.v-chip {
  color: white;
  background: #f44fe3;
  font-size: 10px;
  width: 20px;
  height: 20px;
  padding: 7px;
  font-family: $font-family-medium;
}
</style>
