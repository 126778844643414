export const ProdutoStatus = [
  { label: 'Em rascunho', value: 'DRAFT' },
  { label: 'Aguardando aprovação', value: 'PENDING_REVIEW' },
  { label: 'Em revisão', value: 'IN_REVIEW' },
  { label: 'Aprovado', value: 'APPROVED' },
  { label: 'Reprovado', value: 'REJECTED' },
  { label: 'Publicado', value: 'PUBLISHED' },
  { label: 'Não publicado', value: 'UNPUBLISHED' },
  { label: 'Inativo', value: 'INACTIVE' },
  { label: 'Reprovado', value: 'BANNED' },
];

export const ProdutoStatusOptions = {
  draft: 'Em Rascunho',
  'pending-review': 'Aguardando aprovação',
  'in-review': 'Em revisão',
  approved: 'Aprovado',
  rejected: 'Reprovado',
  published: 'Publicado',
  unpublished: 'Não publicado',
  inactive: 'Inativo',
  banned: 'Reprovado',
};

export default ProdutoStatus;
