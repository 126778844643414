export function mapperToProductResponse(formData) {
  const product = formData.data;
  // eslint-disable-next-line
  for (let i = 0; i < product.skus.length; i++) {
    // eslint-disable-next-line
    product.skus[i].price.list = product.skus[i].price.list / 100;
    // eslint-disable-next-line
    product.skus[i].price.sale = product.skus[i].price.sale / 100;
  }
  return product;
}

export default mapperToProductResponse;
