import { get, remove } from '@/service/api';
import { mapperToProductResponse } from './product-mapper';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';

export default async function getProduct(idProduct) {
  const resp = await get(`ui-integrator/products/${idProduct}`);
  return mapperToProductResponse(resp);
}

export async function listProductProperties(param, filter, itemsPerPage) {
  const { data } = await get(`ui-integrator/product-properties/fetch?size=${itemsPerPage ? `${itemsPerPage}` : '20'}&filter=${param ? `${filter} = !${param}` : ''}`);
  return data;
}

export async function listDadosProductProperties(param, filter, itemsPerPage) {
  return listProductProperties(param, filter, itemsPerPage);
}

export async function deleteSellerProduct(idProduct) {
  const response = await remove(`ui-integrator/products/${idProduct}`);
  return response;
}

export async function getListProduct(formData = {}, paginationOptions) {
  const { page, itemsPerPage, sortDesc, sortBy } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(sortDesc);

  const sortOptions = buildSortUrl(sortBy, mapedOrder);

  const { status, categoryId, productTypeId, nameCode } = formData;

  const query = [
    status ? `( status = ${status} )` : '',
    categoryId ? `( categories.id = ${categoryId} )` : '',
    productTypeId ? `( productTypeId = ${productTypeId} )` : '',
    nameCode ? `( name = !${nameCode} or code = !${nameCode} )` : '',
  ]
    .filter((condition) => condition !== '')
    .join(' and ');

  const data = await get(`ui-integrator/products/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}
