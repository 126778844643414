<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">
          <div class="col-3">
            <label class="filter-label">Categoria</label>
            <custom-select
              class="right-content"
              item-text="name"
              item-value="id"
              placeholder="Todas"
              v-model="form.categoryId"
              :nofilter="true"
              :search="searchCategory"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Tipo</label>
            <custom-select
              class="right-content"
              item-text="displayName"
              item-value="id"
              placeholder="Todos"
              v-model="form.productTypeId"
              :search="searchProductType"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Status</label>
            <v-autocomplete
              class="data_filter_select_input"
              :no-filter="true"
              item-text="label"
              item-value="value"
              v-model="form.status"
              :items="ProdutoStatus"
              placeholder="Todos"
              filled
              dense
              light
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Nome ou código do produto</label>
            <v-text-field v-model="form.nameCode" placeholder="Digite" filled light dense />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler" />
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';
import CustomSelect from '@/components/custom-select/CustomSelect';
import { listCategory } from '@/service/category/category-service';

import { listProductTypes } from '@/service/product-types/product-types-service';
import { ProdutoStatus } from '@/static-data/ProdutoStatusOptions';
import { get } from '@/service/api';

const FilterFields = {
  nameCode: '',
  categoryId: '',
  productTypeId: '',
  customerName: '',
  status: '',
};

export default {
  components: { ContentBaseLayout, FilterBaseActions, CustomSelect },
  data() {
    return {
      form: { ...FilterFields },
      orderStatusOptions: [],
      ProdutoStatus,
    };
  },
  mounted() {
    this.getOrderStatusOptions();
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async getOrderStatusOptions() {
      try {
        const { data } = await get('ui-integrator/orders/statuses');
        this.orderStatusOptions = data?.statuses || [];
      } catch (error) {
        console.error('Error on getOrderStatusOptions', error);
        this.orderStatusOptions = [];
      }
    },
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;

      const formData = { ...this.form };
      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.FilterFields = {
        nameCode: '',
        categoryId: '',
        productTypeId: '',
        customerName: '',
        status: '',
      };
      this.$emit('onReset', this.form);
    },
    async searchCategory(param) {
      const data = await listCategory(param, 'name', 40, 'name', 'ASC');
      return data;
    },
    async searchProductType(param) {
      const data = await listProductTypes(param, 'displayName', 40, 'displayName', 'ASC');
      return data;
    },
  },
};
</script>
